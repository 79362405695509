<template>
  <b-modal
    id="modal-preview"
    v-model="show"
    title="Khảo sát đầu vào"
    centered
    size="lg"
    hide-footer
    @hidden="resetModal"
  >
    <div
      v-for="(section, index) in sections"
      :key="index"
      class="wrap-sections"
    >
      <h6 class="font-weight-bolder text-success">
        Phần {{ index + 1 }}: {{ section.name }}
      </h6>
      <template v-for="(question, idx) in section.questions">
        <Question
          :id="question.id"
          :key="question.id"
          :question="question"
          :type="question.type"
          :showAction="false"
          :questionNumber="idx + 1"
        />
      </template>
    </div>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalPreview',
  components: {
    Question: () => import('./question.vue'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
  },
  methods: {
    resetModal() {
      this.$emit('reset');
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-sections {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
}
</style>
